 
import Header from "../component/Header"
import Footer from "../component/Footer" 
import { Link } from "react-router-dom" 

const EntryToXI = () => {
return(
    <>
    <Header/> 

<div className="breadcrumb">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="d-flex">
                    <h2>ENTRY TO CLASS XI</h2>
                    <ul>
                        <li><Link to="/">Home</Link></li> 
                        <li>|</li> 
                        <li>Admission</li> <li>|</li> 
                        <li>ENTRY TO CLASS XI</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
    <div className="inner-section">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                <h3 className="inner-main-title"><span className="dark-bg">ENTRY TO</span> <span className="light-bg">CLASS XI</span> </h3>
                    <p className="inner-title"> <span>College Department</span></p>
                    <p className="inner-title"> <span>Indian Certificate of Secondary Education ( ICSE )</span></p>
                    <p>From Class XI & Class XII onwards you have the option either to join the science stream, Commerce Stream or the Humanities Stream</p>
                </div>
              
                <div className="col-md-6">
                   <div className="box-light">
                    <div className="heading">
                        <img src="/science.png" alt="St. George’s College"  />
                        <h3>SCIENCE</h3>
                    </div>
                    <div className="content">
                        <ul className="list-view">
                            <li>English</li>
                            <li>Physics</li>
                            <li>Chemistry</li>
                            <li>Maths / Biology</li>
                            <li>* Computer Science / Physical Education / Psychology / Maths</li>
                        </ul>
                    </div>
                   </div>
                </div>
                <div className="col-md-6">
                   <div className="box-light">
                    <div className="heading">
                        <img src="/accounting.png" alt="St. George’s College"  />
                        <h3>COMMERCE</h3>
                    </div>
                    <div className="content">
                        <ul className="list-view">
                            <li>English</li>
                            <li>Economics</li>
                            <li>Accounts</li>
                            <li>Commerce</li>
                            <li>* Computer Science / Physical Education / Psychology / Maths</li>
                        </ul>
                    </div>
                   </div>
                </div>
                <div className="col-md-6">
                   <div className="box-light">
                    <div className="heading">
                        <img src="/humanities.png" alt="St. George’s College"  />
                        <h3>HUMANITIES</h3>
                    </div>
                    <div className="content">
                        <ul className="list-view">
                            <li>English</li>
                            <li>Economics</li>
                            <li>GEOGRAPHY</li>
                            <li>Political Science</li>
                            <li>* Computer Science / Physical Education / Psychology / Maths</li>
                        </ul>
                    </div>
                   </div>
                </div>
            </div>
        </div>
    </div>
    <Footer />
    </>
)
}
export default EntryToXI