 
import Header from "../component/Header"
import Footer from "../component/Footer" 
import { Link } from "react-router-dom" 

const EntryToIX = () => {
return(
    <>
    <Header/> 

<div className="breadcrumb">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="d-flex">
                    <h2>ENTRY TO CLASS IX</h2>
                    <ul>
                        <li><Link to="/">Home</Link></li> 
                        <li>|</li> 
                        <li>Admission</li> <li>|</li> 
                        <li>ENTRY TO CLASS IX</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
    <div className="inner-section">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                <h3 className="inner-main-title"><span className="dark-bg">ENTRY TO </span> <span className="light-bg">CLASS IX</span></h3>
                    <p className="inner-title"> <span>Indian Certificate of Secondary Education ( ICSE )</span></p>
                <p>At St. George’s College we believe the sooner you start specialising, the better it is. From Class IX onwards you have the option either to join the Commerce Stream or the Science Stream.</p>
                </div>
              
                <div className="col-md-6">
                   <div className="box-light">
                    <div className="heading">
                        <img src="/accounting.png" alt="St. George’s College"  />
                        <h3>COMMERCE STREAM</h3>
                    </div>
                    <div className="content">
                        <ul className="list-view">
                            <li>English</li>
                            <li>Second Language : Hindi / French /Nepali</li>
                            <li>History/ Civics & Geography</li>
                            <li>Economics</li>
                            <li>Computer Application</li>
                        </ul>
                    </div>
                   </div>
                </div>
                <div className="col-md-6">
                   <div className="box-light">
                    <div className="heading">
                        <img src="/science.png" alt="St. George’s College"  />
                        <h3>SCIENCE STREAM</h3>
                    </div>
                    <div className="content">
                        <ul className="list-view">
                            <li>English</li>
                            <li>Second Language : Hindi / French /Nepali</li>
                            <li>History/ Civics & Geography</li>
                            <li>Science (Physics, Chemistry & Biology)</li>
                            <li>Computer Application</li>
                        </ul>
                    </div>
                   </div>
                </div>
            </div>
        </div>
    </div>
    <Footer />
    </>
)
}
export default EntryToIX