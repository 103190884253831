import { Link } from 'react-router-dom'
import Header from '../component/Header'
import Footer from '../component/Footer'
const AimObjectives = () => {
    return (
        <>
            <Header />
            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2>Aim And Objectives</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>|</li>
                                    <li>About</li>
                                    <li>|</li>
                                    <li>Aim And Objectives</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inner-section">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-12'>
                        <h3 className="inner-main-title"><span className="dark-bg">Aim And 
</span> <span className="light-bg">Objectives</span></h3>
                        </div>
                        <div className='col-md-6'>
                            <div className='aim-img'>
                                <img src="/goal.jpg" className='img-fluid about-img1' alt="St. George college" /> 
                            </div>
                        </div>
                        <div className='col-md-6'>                       
                        <p>We aim to lay the foundation of a life-long educational experience, which will give the individual confidence and respect that will overcome all distinctions of race, class, colour and creed.</p>
                        <p>We also wish to develop in our students a consciousness of the needs of society and the desire to contribute positively through selfless service, putting the totality of God-given talents and acquired knowledge to the service of humanity.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default AimObjectives