import React, { useEffect, useRef, useState } from 'react'
import { getHighlight } from '../Service/Api';
import { Link } from 'react-router-dom'
const Highlight = (props) => {
    const marqueeRef = useRef(null);
    const handleMouseOver = () => {
      marqueeRef.current.stop();
    };  
    const handleMouseOut = () => {
      marqueeRef.current.start();
    };
    const [data, setData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const data = await getHighlight();
      console.log(data);
      setData(data);
    };
    getData();
  }, []);
  
  
  const emptyArray = [
    { description: "Stay tuned for latest updates" }
  ];
    return(
        <>
        <div className="highlights">
             <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-2 col-sm-3">
                        <img src="/notification.gif" /> Announcement
                    </div>
                    <div className="col-lg-10 col-sm-9">
                        <marquee  onMouseOver={(event) => event.currentTarget.stop()}
          onMouseOut={(event) => event.currentTarget.start()}>
             {data.length > 0 ? ( data.map((item, index) => (
                            <p>{item.description}{item.attachments.length > 0 && ( <a href={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} target="_blank"><i class="bi bi-box-arrow-up-right"></i></a> )} </p>
                            ))
                            ) : (
                              emptyArray.map((data, index) => (
                                <>
                                <p>Stay Tuned For More Updates</p>
                                </>
                                ))
          )}
                            {/* <p>Exam Form Apply Link, from date 20-04-2024 to 04-05-2024, will be.....<a href="" target="_blank"><i class="bi bi-box-arrow-up-right"></i></a></p> */}
                        </marquee>
                    </div>
                </div>
             </div>
        </div>
        </>
    )
}
export default Highlight