import { Link } from 'react-router-dom'
import Header from "../component/Header"
import Footer from "../component/Footer"
const PricipalMessageInner = () => {
    return (
        <>
            <Header />
            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2>Principal's Message</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>|</li>
                                    <li>About</li>
                                    <li>|</li>
                                    <li>Principal's Message</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inner-section about ">
                <div className="container-fluid">
                    <div className="row">
                        <div className='col-md-12'>
                            <h3 className="inner-main-title"><span className="dark-bg">Principal</span> <span className="light-bg"> Message</span></h3>
                        </div>
                        <div className="col-md-4">
                            <img src='/principal.png' className="img-fluid principalMessage" alt="St. George’s College"  />
                        </div>
                        <div className="col-md-8">
                            <p><i>“Education is not the filling of a pail but the lighting of a fire.” –William Butler Yeats.</i></p>
                            <p>Voiced centuries ago, W.B. Yeats could not have spoken a truer word. Today we live in a changed world that is extremely competitive, radically developed and astonishingly dynamic. Education in itself has undergone a sea-change with the classroom becoming a platform for global ideas. Personalized learning, an increased focus on mental health, the growing importance of online classes and the impact of artificial intelligence all serve to underline the idea that education has come a long way from our traditional gurukul system.  However, the primary idea that education is a tool to draw out the intrinsic skills that lie within an  individual, still holds good.</p>
                            <p>Today, the start-up culture owes its roots to the modern classroom and more than ever, the community of educators the world over must continually strive to keep abreast of emerging trends not just in terms of teaching but also in contributing to the global village. </p>
                            <p>At St. George’s College, emphasis is placed on the individual happiness of the child and strengthening the value system that allows the student to take his place in the evolving world. It is only when the individual is strongly equipped with a set of principles that he can become an invaluable asset to his community and the larger world outside his immediate circle.</p>
                            <p>The school equips the student to successfully forge a career for himself. More importantly, he  learns to contribute to society, to appreciate and respect humanity and to imbibe the values of tolerance, selflessness and sensitivity in a world that is increasingly intolerant.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default PricipalMessageInner