import React, { useEffect, useState } from 'react';
import { getTopper } from '../Service/Api';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom'
const Topper = () => {
    const [classXData, setClassXData] = useState([]);
    const [classXIIData, setClassXIIData] = useState([]);
   
    useEffect(() => {
        const fetchData = async () => {
            const topperData = await getTopper();
            const currentYear = new Date().getFullYear();
            const yearString = `${currentYear}-${currentYear + 1}`;
            console.log(yearString)
            const classX = topperData.filter((item) => item.class === 'X' && item.sessionYear === yearString); // Adjust the filtering condition as needed
            console.log(classX)
            setClassXData(classX); 

            const classXII = topperData.filter((item) => item.class === 'XII' && item.sessionYear === yearString); // Adjust the filtering condition as needed
            setClassXIIData(classXII); 
            
            console.log("Filtered class X data for year", yearString, classX);
        };
        fetchData();
    }, []);


    const emptyArray = [
        { name: 'Student Name', stream: 'Stream', division: '100%', attachments: 'topper.jpg' },
    ];


    
    var settings = {
        loop: true,
        margin: 0,
        responsiveClass: true,
        autoplay: true,
        dots: false,
        controls: true,
        items: 4,
        responsive: {
            0: {
                items: 1,
            },
            567: {
                items: 2,
            },
            768: {
                items: 3,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 3,
            },
            1600: {
                items: 4,
            }
        }
    };

    return (
        <>
            <div className="tpr-block  animateMe" data-animation="fadeInDown">
                <div><h3>12<sup>th</sup> TOPPERS</h3></div>
                <div className='d-flex'>

                <OwlCarousel className='owl-theme topper-theme' loop margin={10} {...settings}>
                {classXIIData.length>0 ? (classXIIData.map((item1, index) => (
                    <div className="item" key={index}> 
                    <div>
                        <div className='topper-img'>
                            <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item1.attachments}`}></img>
                        </div>
                        <div className='topper-cont'>
                            <p className='percent'>{item1?.division}</p>
                            <p>{item1?.name}</p>
                            <p>{item1?.stream}</p>
                        </div>
                        </div>
                    </div>
                    ))):(
                        <div className="item"> 
                    <div>
                        <div className='topper-img'>
                            <img src="/user.png"></img>
                        </div>
                        <div className='topper-cont'>
                            <p className='percent'>%</p>
                            <p>Student Name</p>
                            <p>Stream</p>
                        </div>
                        </div>
                    </div>
                    )
                    }
                   
                </OwlCarousel>
                <div className='button'>
                    <Link to="/TopperXII"><button>VIEW ALL</button></Link>
                </div>
                </div>
            </div>
            <div className="tpr-block animateMe" data-animation="fadeInUp"> 
            <div><h3>10<sup>th</sup> TOPPERS</h3></div>
            <div className='d-flex'>
                <OwlCarousel className='owl-theme topper-theme' loop margin={10} {...settings}>
                {classXData.length>0 ? (classXData.map((item1, index) => (
                    <div className="item" key={index}>
                        <div>
                        <div className='topper-img'>
                            <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item1.attachments}`}></img>
                        </div>
                        <div className='topper-cont'>
                            <p className='percent'>{item1?.division}</p>
                            <p>{item1?.name} </p>
                            <p>{item1?.stream}</p>
                        </div>
                        </div>
                    </div>
                     ))):(<div className="item"> 
                     <div>
                         <div className='topper-img'>
                             <img src="/user.png"></img>
                         </div>
                         <div className='topper-cont'>
                             <p className='percent'>%</p>
                             <p>Student Name</p>
                             <p>Stream</p>
                         </div>
                         </div>
                     </div>)
                    }
                    {/* <div className="item">

                        <div>
                        <div className='topper-img'>
                            <img src="/ANIMESH.jpg"></img>
                        </div>
                        <div className='topper-cont'>
                            <p className='percent'>95.6%</p>
                            <p>ANIMESH KHANDELWAL</p>
                            <p>-</p>
                        </div>
                        </div>
                    </div>
                    <div className="item">
                        <div>
                        <div className='topper-img'>
                            <img src="/OM.jpg"></img>
                        </div>
                        <div className='topper-cont'>
                            <p className='percent'>97.8%</p>
                            <p>OM RAJPUT</p>
                            <p>-</p>
                        </div>
                        </div>
                    </div>
                    <div className="item">
                        <div>
                        <div className='topper-img'>
                            <img src="/SOMIL.jpg"></img>
                        </div>
                        <div className='topper-cont'>
                            <p className='percent'>97.4%</p>
                            <p>SOMIL KUMAR JHUNJHUNWALA  </p>
                            <p>-</p>
                        </div>
                        </div>
                    </div>
                    <div className="item">
                        <div>
                        <div className='topper-img'>
                            <img src="/VEDANT.jpg"></img>
                        </div>
                        <div className='topper-cont'>
                            <p className='percent'>93.8%</p>
                            <p>VEDANT SINGHAL</p>
                            <p>-</p>
                        </div>
                        </div>
                    </div> */}
                </OwlCarousel>
                <div className='button'>
                    <Link to="/TopperX"><button>VIEW ALL</button></Link>
                </div>
                </div>
            </div>
        </>
    );
};

export default Topper;
